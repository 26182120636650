import * as React from "react"
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import {graphql} from 'gatsby';
import MyPortableText from "../components/MyPortableText";
import {useEffect, useRef, useState} from "react";
import ImageGallery from "react-image-gallery";
import ProgrammPunkt24 from "../components/ProgrammPunkt24";
import Landing from "../components/Landing";
import Partner from "../components/Partner";

export const query = graphql`
	{
		allSanityMdz24Graz {
			edges {
				node {
					credits
					date
					year
					headerlocations
					image{ asset{ gatsbyImageData}}
					_rawIntrotext
					_rawAdditionaltext
					programmactive
					programm1 {
						title
						time
						text
						moderation
						kooperation
						zusatzinfo
						with
						audiofile {asset{url}}
						link
						linktitle
					}
					programm1date
					programm1location
					programm1locationlink
					programm2date
					programm2location
					programm2locationlink
					programm3date
					programm3location
					programm3locationlink
					programm2 {
						title
						time
						text
						moderation
						kooperation
						zusatzinfo
						with
						audiofile {asset{url}}
						link
						linktitle
					}
					programm3 {
						title
						time
						text
						moderation
						kooperation
						zusatzinfo
						with
						audiofile {asset{url}}
						link
						linktitle
					}
					initiativen{
						fb
						insta
						linkedin
						website
						title
						_rawBody
					}
					archive{
						credits
						image{ asset{ gatsbyImageData}}
						title
						_rawText
						link
						linktext
					}
					movieid
					images{ asset{ gatsbyImageData
						url}}
					galeriecredits
				}

			}
		}
	}
`;

let images = [];

const IndexPage = ({data}) => {

	const page = data.allSanityMdz24Graz.edges[0].node;


	const [open, setOpen] = useState(false);

	const toggle = () => {
		setOpen(!open);
	};

	const contentRef = useRef();

	let introTextRef = useRef();


	useEffect(() => {
		console.log("useEffect");
		console.log(page.images);
		page.images.map((el) => {
			const image = {
				original: el?.asset?.url,
				thumbnail: el?.asset?.url
			}
			images.push(image);
		})
	});

	console.log(page);

	return (
		<Layout newsletter={true}>
			<SEO/>
			<Landing headerlocations={page?.headerlocations} customlocations={true} programmActive={page?.programmactive} image={page.image.asset.gatsbyImageData} credits={page.credits} date={page?.date} year={page?.year} link={"#programm"} linktext={"PROGRAMM"} location1={page.programm1location} location2={page.programm2location} location3={page.programm3location}></Landing>
			<div className="intro__outer" id={"about"}>
				<div className="intro__inner">
					<div className="intro__text__container">
						<h3 className={"intro__text half"} ref={introTextRef}>
							<MyPortableText value={page._rawIntrotext}></MyPortableText>
						</h3>
					</div>
					<div className="intro__collapsed" ref={contentRef}
						 style={open ? {height: contentRef.current.scrollHeight + "px"} : {height: "0px"}}>
						<MyPortableText value={page._rawAdditionaltext}></MyPortableText>
					</div>
					<div className="btn__outer flex marginTop box">
						<div className="btn__inner light right" onClick={toggle}>
							{open ? "Weniger Info" : "Mehr Info"}
							<svg className={open ? "rotated" : ""} xmlns="http://www.w3.org/2000/svg" width="18.347" height="18.346" viewBox="0 0 18.347 18.346">
								<g className={"svg-path-dark svg-path"} id="Group_169" data-name="Group 169" transform="translate(-1755.807 -3662.46)">
									<path id="Path_274" data-name="Path 274" d="M135.631,11.23l10.144,10.145" transform="translate(1677.016 3560.613) rotate(45)"/>
									<path id="Path_275" data-name="Path 275" d="M0,0,10.145,10.144" transform="translate(1772.154 3671.633) rotate(135)"/>
								</g>
							</svg>
						</div>
					</div>
				</div>
			</div>
			{page.movieid ? <div className="section__outer">
				<div className="section__inner" id={"video"}>
					<h4 className={"uppercase box"}>Aftermovie</h4>
					<div className="separator"></div>
					<iframe width="560" height="315" src={`https://www.youtube.com/embed/${page?.movieid}?si=BzOGOdQRdClBf6ih`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
				</div>
			</div>: <></>}
			{images.length > 0 ? <div className="section__outer">
				<div className="section__inner" id={"programm"}>
					<h4 className={"uppercase box"}>Galerie</h4>
					<div className="separator"></div>
					<ImageGallery items={images}/>
					<p className="small uppercase">FOTOS: {page.galeriecredits}</p>
				</div>
			</div> : <></>}
			{page.programmactive ? <div className="section__outer">
				<div className="section__inner" id={"programm"}>
					<h4 className={"uppercase box"}>Programm</h4>
					<div className="separator"></div>
					<h2>{page.programm1date}</h2>
					<div className="prorgamm__location__outer">
						<h4 className={"uppercase"}>{page.programm1location}</h4>
						<div className="btn__outer">
							<a href={page.programm1locationlink} rel="noreferrer" target={"_blank"} className="btn__inner light">
								Google Maps
								<svg xmlns="http://www.w3.org/2000/svg" width="15.694" height="20.932" viewBox="0 0 15.694 20.932">
									<g className="svg-path svg-path-dark">
										<path id="Path_86" data-name="Path 86" d="M2877.2,1135.355h.738c.128.014.255.026.383.042a6.861,6.861,0,0,1,5.947,8.147,12.78,12.78,0,0,1-1.355,3.575,36.964,36.964,0,0,1-4.781,6.838.644.644,0,0,1-1.124,0,38.862,38.862,0,0,1-4.3-5.964,17.033,17.033,0,0,1-1.692-3.839,6.614,6.614,0,0,1,.257-4.6,6.721,6.721,0,0,1,5.369-4.121C2876.824,1135.4,2877.014,1135.379,2877.2,1135.355Z" transform="translate(-2869.718 -1134.355)"/>
										<path id="Path_87" data-name="Path 87" d="M2877.271,1144.516a2.611,2.611,0,1,0-2.6-2.607A2.614,2.614,0,0,0,2877.271,1144.516Z" transform="translate(-2869.417 -1134.051)"/>
									</g>
								</svg>
							</a>
						</div>
					</div>
					<div className="coll3 longer">
						{page.programm1.map(el => (
							<ProgrammPunkt24 link={el.link} linktitle={el.linktitle} title={el.title} time={el.time} mit={el.with} text={el.text} moderation={el.moderation} kooperation={el.kooperation} zusatzinfo={el.zusatzinfo}></ProgrammPunkt24>
						))}
					</div>
					<div className="separator"></div>
					<h2>{page.programm2date}</h2>
					<div className="prorgamm__location__outer">
						<h4 className={"uppercase"}>{page.programm2location}</h4>
						<div className="btn__outer">
							<a href={page.programm2locationlink} rel="noreferrer" target={"_blank"} className="btn__inner light">
								Google Maps
								<svg xmlns="http://www.w3.org/2000/svg" width="15.694" height="20.932" viewBox="0 0 15.694 20.932">
									<g className="svg-path svg-path-dark">
										<path id="Path_86" data-name="Path 86" d="M2877.2,1135.355h.738c.128.014.255.026.383.042a6.861,6.861,0,0,1,5.947,8.147,12.78,12.78,0,0,1-1.355,3.575,36.964,36.964,0,0,1-4.781,6.838.644.644,0,0,1-1.124,0,38.862,38.862,0,0,1-4.3-5.964,17.033,17.033,0,0,1-1.692-3.839,6.614,6.614,0,0,1,.257-4.6,6.721,6.721,0,0,1,5.369-4.121C2876.824,1135.4,2877.014,1135.379,2877.2,1135.355Z" transform="translate(-2869.718 -1134.355)"/>
										<path id="Path_87" data-name="Path 87" d="M2877.271,1144.516a2.611,2.611,0,1,0-2.6-2.607A2.614,2.614,0,0,0,2877.271,1144.516Z" transform="translate(-2869.417 -1134.051)"/>
									</g>
								</svg>
							</a>
						</div>
					</div>
					<div className="coll__outer">
						<div className="coll3">
							{page.programm2.slice(0, page.programm2.length / 2).map(el => (
								<ProgrammPunkt24 mdz24={true}  link={el.link} linktitle={el.linktitle} title={el.title} time={el.time} mit={el.with} text={el.text} moderation={el.moderation} kooperation={el.kooperation} zusatzinfo={el.zusatzinfo} url={el.audiofile}></ProgrammPunkt24>
							))}
						</div>
						<div className="coll3">
							{page.programm2.slice(page.programm2.length / 2, page.programm2.length).map(el => (
								<ProgrammPunkt24 mdz24={true}  link={el.link} linktitle={el.linktitle} title={el.title} time={el.time} mit={el.with} text={el.text} moderation={el.moderation} kooperation={el.kooperation} zusatzinfo={el.zusatzinfo} url={el.audiofile}></ProgrammPunkt24>

							))}
						</div>
					</div>
					<div className="separator"></div>
					<h2>{page.programm3date}</h2>
					<div className="prorgamm__location__outer">
						<h4 className={"uppercase"}>{page.programm3location}</h4>
						<div className="btn__outer">
							<a href={page.programm3locationlink} rel="noreferrer" target={"_blank"} className="btn__inner light">
								Google Maps
								<svg xmlns="http://www.w3.org/2000/svg" width="15.694" height="20.932" viewBox="0 0 15.694 20.932">
									<g className="svg-path svg-path-dark">
										<path id="Path_86" data-name="Path 86" d="M2877.2,1135.355h.738c.128.014.255.026.383.042a6.861,6.861,0,0,1,5.947,8.147,12.78,12.78,0,0,1-1.355,3.575,36.964,36.964,0,0,1-4.781,6.838.644.644,0,0,1-1.124,0,38.862,38.862,0,0,1-4.3-5.964,17.033,17.033,0,0,1-1.692-3.839,6.614,6.614,0,0,1,.257-4.6,6.721,6.721,0,0,1,5.369-4.121C2876.824,1135.4,2877.014,1135.379,2877.2,1135.355Z" transform="translate(-2869.718 -1134.355)"/>
										<path id="Path_87" data-name="Path 87" d="M2877.271,1144.516a2.611,2.611,0,1,0-2.6-2.607A2.614,2.614,0,0,0,2877.271,1144.516Z" transform="translate(-2869.417 -1134.051)"/>
									</g>
								</svg>
							</a>
						</div>
					</div>
					<div className="coll__outer">
						{page?.programm3?.length > 1 ?
							<>
							<div className="coll3">
								{page.programm3.slice(0, page.programm3.length / 2).map(el => (
									<ProgrammPunkt24 mdz24={true}  link={el.link} linktitle={el.linktitle} title={el.title} time={el.time} mit={el.with} text={el.text} moderation={el.moderation} kooperation={el.kooperation} zusatzinfo={el.zusatzinfo} url={el.audiofile}></ProgrammPunkt24>
								))}
							</div>
							<div className="coll3">
								{page.programm3.slice(page.programm3.length / 2, page.programm3.length).map(el => (
									<ProgrammPunkt24 mdz24={true}  link={el.link} linktitle={el.linktitle} title={el.title} time={el.time} mit={el.with} text={el.text} moderation={el.moderation} kooperation={el.kooperation} zusatzinfo={el.zusatzinfo} url={el.audiofile}></ProgrammPunkt24>

								))}
							</div></>:
						<div className="coll3 longer">
							{page.programm3.map(el => (
								<ProgrammPunkt24 mdz24={true} title={el.title} time={el.time} mit={el.with} text={el.text} moderation={el.moderation} kooperation={el.kooperation} zusatzinfo={el.zusatzinfo}></ProgrammPunkt24>
							))}
						</div>}
					</div>
				</div>
			</div> : <></>}
			<Partner/>
		</Layout>
	);
}

export default IndexPage


/*
<Marquee speed={100} gradient={false}>
<div className={"marqueeText"}>
<h1 className="marqueeText__inner">Newsletter <div className="marquee__image"><StaticImage src={"../images/newsletter.jpg"} alt={"Newsletter"}></StaticImage></div></h1>
<h1 className="marqueeText__inner">Newsletter <div className="marquee__image"><StaticImage src={"../images/newsletter.jpg"} alt={"Newsletter"}></StaticImage></div></h1>
<h1 className="marqueeText__inner">Newsletter <div className="marquee__image"><StaticImage src={"../images/newsletter.jpg"} alt={"Newsletter"}></StaticImage></div></h1>
<h1 className="marqueeText__inner">Newsletter <div className="marquee__image"><StaticImage src={"../images/newsletter.jpg"} alt={"Newsletter"}></StaticImage></div></h1>
<h1 className="marqueeText__inner">Newsletter <div className="marquee__image"><StaticImage src={"../images/newsletter.jpg"} alt={"Newsletter"}></StaticImage></div></h1>
</div>
</Marquee>


                        <!--<GatsbyImage image={page.image.asset.gatsbyImageData} alt={"Markt der Zukunft - Keyvisual"} placeholder={"dominantColor"}></GatsbyImage>-->

*/
